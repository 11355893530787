import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import AuthUtils, { IdentificationKeyLocalStorage } from 'shared/util/auth-utils';

const createApiInstance = (): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60 * 1000,
  };
  const instance = axios.create(config);

  instance.interceptors.request.use(async configInstance => {
    const token = AuthUtils.getToken(IdentificationKeyLocalStorage.CORE_RESPONSE);
    if (token != null) {
      configInstance.headers!.Authorization = `Bearer ${token}`;
    }
    return configInstance;
  });

  return instance;
};

const createCepeaApiInstance = (): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL:
      'https://www.cepea.esalq.usp.br/br/widgetproduto.js.php?fonte=arial&tamanho=10&largura=400px&corfundo=dbd6b2&cortexto=333333&corlinha=ede7bf',
    timeout: 60 * 1000,
  };

  const instance = axios.create(config);
  return instance;
};

const createCurrencyApiInstance = (): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: 'https://economia.awesomeapi.com.br/last/USD-BRL,EUR-BRL,GBP-BRL',
    timeout: 60 * 1000,
  };

  const instance = axios.create(config);
  return instance;
};

const createWeatherApiInstance = (): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: 'https://api.openweathermap.org/data/2.5/',
    timeout: 60 * 1000,
  };

  const instance = axios.create(config);
  return instance;
};

const createContentfulApiInstance = (): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: 'https://cdn.contentful.com/spaces/stkj3oncev65/environments/master',
    timeout: 60 * 1000,
  };

  const instance = axios.create(config);
  return instance;
};

const createContentfulFrisiaApiInstance = (): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: 'https://contentful.frisia.coop.br/api/',
    timeout: 60 * 1000,
  };

  const instance = axios.create(config);
  return instance;
};

const createExtranetApiInstance = (): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: 'https://extranet.frisia.coop.br/Suporte/',
    timeout: 60 * 1000,
  };

  const instance = axios.create(config);
  return instance;
};

const createCoreApiInstance = () => {
  const config: AxiosRequestConfig = {
    baseURL: 'https://servicos.frisia.coop.br/Core/api',
    timeout: 600 * 1000,
  };
  const instance = axios.create(config);
  instance.interceptors.request.use(async configInstance => {
    let token: any = AuthUtils.getToken(IdentificationKeyLocalStorage.CORE_RESPONSE);

    if (token != null) {
      token = JSON.parse(token);
      configInstance.headers!.Authorization = `Bearer ${token['access_token']}`;
    }
    configInstance.headers!.cd_aplicativo = 999;
    return configInstance;
  });
  return instance;
};

const createTermosAutorizacaoApiInstance = () => {
  const config: AxiosRequestConfig = {
    baseURL: 'https://servicos.frisia.coop.br/TermosAutorizacao/api/PDF',
    timeout: 60 * 1000,
  };
  const instance = axios.create(config);
  instance.interceptors.request.use(async configInstance => {
    let token: any = AuthUtils.getToken(IdentificationKeyLocalStorage.CORE_RESPONSE);

    if (token != null) {
      token = JSON.parse(token);
      configInstance.headers!.Authorization = `Bearer ${token['general_access_token']}`;
    }
    return configInstance;
  });
  return instance;
};

const createDevCoreApiInstance = () => {
  const config: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_CORE_DEV_URL,
    timeout: 60 * 1000,
  };
  const instance = axios.create(config);
  instance.interceptors.request.use(async configInstance => {
    let token: any = AuthUtils.getToken(IdentificationKeyLocalStorage.DEV_CORE_RESPONSE);

    if (token != null) {
      token = JSON.parse(token);
      configInstance.headers!.Authorization = `Bearer ${token['access_token']}`;
    }
    return configInstance;
  });
  return instance;
};

const createOracleApiInstace = () => {
  const config: AxiosRequestConfig = {
    baseURL: 'https://efln.fa.us6.oraclecloud.com/crmRestApi/resources/11.13.18.05/',
    timeout: 60 * 1000,
    auth: {
      username: 'integracao2',
      password: 'KB^8gk#M4',
    },
  };

  const instance = axios.create(config);

  return instance;
};

const createApiIndicatorsInstance = () => {
  const config: AxiosRequestConfig = {
    baseURL: 'https://servicos.frisia.coop.br/Rest/api/',
    timeout: 60 * 1000,
  };
  const instance = axios.create(config);
  instance.interceptors.request.use(async configInstance => {
    let token: any = AuthUtils.getToken(IdentificationKeyLocalStorage.INDICATORS_TOKEN);
    if (token != null) {
      token = JSON.parse(token);
      configInstance.headers!.Authorization = `Bearer ${token['access_token']}`;
    }
    return configInstance;
  });
  return instance;
};

const createApiExtranetFilesInstance = () => {
  const config: AxiosRequestConfig = {
    baseURL: 'https://servicos.frisia.coop.br/coopfiles/api',
    timeout: 60 * 1000,
  };

  const instance = axios.create(config);

  instance.interceptors.request.use(async configInstance => {
    let token: any = AuthUtils.getToken(IdentificationKeyLocalStorage.CORE_RESPONSE);

    if (token != null) {
      token = JSON.parse(token);
      configInstance.headers!.Authorization = `Bearer ${token['general_access_token']}`;
    }

    return configInstance;
  });

  return instance;
};

const createApiBiEmbedInstance = () => {
  const config: AxiosRequestConfig = {
    baseURL: 'https://servicos.frisia.coop.br/biembed/api',
    timeout: 60 * 1000,
  };

  const instance = axios.create(config);

  instance.interceptors.request.use(async configInstance => {
    let token: any = AuthUtils.getToken(IdentificationKeyLocalStorage.CORE_RESPONSE);

    if (token != null) {
      token = JSON.parse(token);
      configInstance.headers!.Authorization = `Bearer ${token['general_access_token']}`;
    }

    return configInstance;
  });

  return instance;
};

export const api = createApiInstance();
export const apiCepea = createCepeaApiInstance();
export const apiCurrency = createCurrencyApiInstance();
export const apiWeather = createWeatherApiInstance();
export const apiContentful = createContentfulApiInstance();
export const apiContentfulFrisia = createContentfulFrisiaApiInstance();
export const apiCore = createCoreApiInstance();
export const apiTermosAutorizacao = createTermosAutorizacaoApiInstance();
export const apiExtranet = createExtranetApiInstance();
export const apiOracle = createOracleApiInstace();
export const apiIndicators = createApiIndicatorsInstance();
export const apiExtranetFiles = createApiExtranetFilesInstance();
export const apiBiEmbed = createApiBiEmbedInstance();
export const apiDevCore = createDevCoreApiInstance();

export default {
  api,
  apiCepea,
  apiCurrency,
  apiWeather,
  apiContentful,
  apiExtranet,
  apiContentfulFrisia,
};
