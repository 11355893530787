import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import { StyledButton } from 'components/styled-button/StyledButton';
import TextInputCadastralSheet from 'components/TextInputCadastralSheet';
import dayjs from 'dayjs';
import {
  IBankData,
  IChildren,
  IContact,
  ICooperatorAreas,
  ICooperatorMachines,
  ICooperatorProperties,
  IPersonalData,
  IRelationship,
} from 'model/registration-form';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import calendarService from 'services/calendar-oracle-service';
import registrationFormService from 'services/registration-form-service';
import StringUtils from 'shared/util/string-utils';
import styled from 'styled-components';
import { StyledRegistrationFormBackground, StyledRegistrationFormSubtitle } from 'styled-components/StyledRegistrationForm';
import * as yup from 'yup';
import mockCadastralForm from '../../api/mocks/mockCadastralForm.json';
import { ReactComponent as EditIconSvg } from '../../images/frisia/icons/blue-edit-icon.svg';

interface IForm {
  maritalStatus: string;
  address: string;
  district: string;
  city: string;
  DDD: string;
  phone: string;
  telephone: string;
  personalEmail: string;
  emailNF: string;
  cellPhone: string;
}

const PersonalData = ({ t }: WithTranslation) => {
  const [personalData, setPersonalData] = useState<IPersonalData>();
  const [cooperatorAreasActivities, setcooperatorAreasActivities] = useState<ICooperatorAreas[]>([]);
  const [cooperatorProperties, setCooperatorProperties] = useState<ICooperatorProperties[]>([]);
  const [cooperatorMachines, setCooperatorMachines] = useState<ICooperatorMachines[]>([]);
  const [bankData, setBankData] = useState<IBankData>();
  const [relationshipData, setRelationshipData] = useState<IRelationship>();

  const [contactData, setContactData] = useState<IContact[]>([]);

  const [children, setChildren] = useState<IChildren[]>([]);

  const [updateContact, setUpdateContact] = useState<boolean>(false);

  const getUserAccountDetails = async () => {
    const data = await calendarService.getAccountBycdAndName(String(userRegistration), userName);
    registrationFormService.getRelationshipData(data.items[0].PartyNumber).then(setRelationshipData);
  };

  useEffect(() => {
    if (userRegistration != null && userName != null) {
      registrationFormService.personalData(userRegistration).then(setPersonalData);
      registrationFormService.getProperties(userRegistration).then(setCooperatorProperties);
      registrationFormService.getMachines(userRegistration).then(setCooperatorMachines);
      registrationFormService
        .getAreasCooperative(userRegistration)
        .then(response => setcooperatorAreasActivities(response.filter(area => area.STATUS === 'INTEGRADO')));
      registrationFormService.getBankData(userRegistration).then(setBankData);

      registrationFormService.getChildren(userRegistration.toString()).then(setChildren);

      registrationFormService.getContact(userRegistration.toString()).then(setContactData);

      getUserAccountDetails();
    }
  }, []);

  const schema = yup.object({
    telephone: yup.string().required(),
    maritalStatus: yup.string().required(),
    address: yup.string().required(),
    district: yup.string().required(),
    city: yup.string().required(),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const handleUpdateRegistrationForm = (data: IForm) => {
    registrationFormService
      .updateContact({
        CD_PESSOA: userRegistration,
        NR_DDD: data.DDD,
        NR_TELEFONE_FIXO: data.telephone,
        DS_EMAIL_PESSOAL: data.personalEmail,
        DS_EMAIL_NF: data.emailNF,
        NR_CELULAR_WHATS: data.cellPhone,
      })
      .then(response => {
        registrationFormService.getContact(userRegistration.toString()).then(setContactData);
      });
  };

  const userRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const userName = useSelector<IRootState, string>(state => {
    return state.authentication.selectedInfo?.NM_PESSOA ?? state.authentication.coreInfo?.nm_usuario;
  });

  const convertRelationshipCode = (relationshipCode: string) => {
    if (relationshipCode == 'CONTACT_OF') {
      return t('registrationForm.contactOf');
    } else if (relationshipCode == 'PARTNER_OF') {
      return t('registrationForm.partnerOf');
    } else {
      return relationshipCode;
    }
  };

  const tableHeaderRelationship = [t('table.relationship'), t('table.name')];
  const tableHeaderActivities = [t('table.activity'), t('table.status'), t('table.classification'), t('table.admission')];
  const tableHeaderProperties = [t('table.propertie'), t('table.county')];
  const tableHeaderChildren = [
    t('registrationForm.name'),
    t('registrationForm.birthday'),
    t('registrationForm.sex'),
    'DDD',
    t('registrationForm.tel'),
    t('registrationForm.email'),
  ];
  const tableHeaderMachinesAndEquipment = [
    t('table.machineModel'),
    t('table.chassis'),
    t('table.conservation'),
    t('table.value'),
    t('table.alienated'),
    t('table.dueDate'),
  ];
  const tableHeaderSquad = [
    t('table.animalRace'),
    t('table.quantity'),
    t('table.degreeMiscegenation'),
    t('table.color'),
    t('table.finality'),
    t('table.middleAge'),
  ];

  const footerTableProperties = (
    <>
      <StyledFooterTableData>{''}</StyledFooterTableData>
      <StyledFooterTableData>{t('table.total')}</StyledFooterTableData>
      <StyledFooterTableData>{mockCadastralForm.areaTotal}</StyledFooterTableData>
      <StyledFooterTableData>{mockCadastralForm.areaAgricultavelTotal}</StyledFooterTableData>
      <StyledFooterTableData>{''}</StyledFooterTableData>
    </>
  );

  return (
    <StyledRegistrationFormBackground>
      <StyledHeaderForm>
        <StyledTitleContainer>
          <EditIconSvg />
          <span>{t('registrationForm.personalData')}</span>
        </StyledTitleContainer>
      </StyledHeaderForm>

      {personalData != null && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleUpdateRegistrationForm)}>
            <TextInputCadastralSheet
              isReadOnly
              inputName="area"
              label={t('registrationForm.area')}
              defaultValue={cooperatorAreasActivities.map(area => area.DS_TIPO_ATIVIDADE).join(', ')}
            />

            <Grid container columnSpacing={1.5}>
              <Grid item xs={12} lg={8}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="memberName"
                  label={t('registrationForm.memberName')}
                  defaultValue={personalData?.NOME ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="birthDate"
                  label={t('registrationForm.birthDate')}
                  defaultValue={personalData?.DT_NASCIMENTO ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={5}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="admissionDate"
                  label={t('registrationForm.admissionDate')}
                  defaultValue={personalData?.DT_ADMISSAO ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="renewalDate"
                  label={t('registrationForm.renewalDate')}
                  defaultValue={personalData?.DT_RENOVAR ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="individualRegistration"
                  label={t('registrationForm.individualRegistration')}
                  defaultValue={StringUtils.maskCpf(String(personalData?.CPF))}
                />
              </Grid>
              <Grid item xs={12} sm={7} lg={2}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="generalRegistration"
                  label={t('registrationForm.generalRegistration')}
                  defaultValue={personalData?.RG ?? ''}
                />
              </Grid>
              <Grid item xs={12} sm={5} lg={2}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="issuingBody"
                  label={t('registrationForm.issuingBody')}
                  defaultValue={personalData?.ORGAO_EXPEDIDOR ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={5}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="birthPlace"
                  label={t('registrationForm.birthPlace')}
                  defaultValue={personalData?.NATURAL ?? ''}
                />
              </Grid>
              <Grid item xs={12} sm={5} lg={3}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="maritalStatus"
                  label={t('registrationForm.maritalStatus')}
                  defaultValue={personalData?.ESTADO_CIVIL ?? ''}
                  errorText={methods.formState.errors.maritalStatus?.message}
                />
              </Grid>
              <Grid item xs={12} sm={7} lg={2}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="regime"
                  label={t('registrationForm.regime')}
                  defaultValue={personalData?.REGIME ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="address"
                  label={t('registrationForm.address')}
                  defaultValue={personalData?.ENDERECO ?? ''}
                  errorText={methods.formState.errors.address?.message}
                />
              </Grid>
              <Grid item xs={12} sm={7} lg={3}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="district"
                  label={t('registrationForm.district')}
                  defaultValue={personalData?.BAIRRO ?? ''}
                  errorText={methods.formState.errors.district?.message}
                />
              </Grid>
              <Grid item xs={12} sm={5} lg={3}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="city"
                  label={t('registrationForm.city')}
                  defaultValue={personalData?.MUNICIPIO ?? ''}
                  errorText={methods.formState.errors.city?.message}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="zipCode"
                  label={t('registrationForm.zipCode')}
                  defaultValue={String(personalData?.CEP) ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={7}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="email"
                  label={t('registrationForm.email')}
                  defaultValue={personalData?.EMAIL ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="phone"
                  label={t('registrationForm.phone')}
                  defaultValue={personalData?.FONE ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={5}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="filiation"
                  label={t('registrationForm.filiation')}
                  defaultValue={personalData?.FILIACAO ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="howManyEmployees"
                  label={t('registrationForm.howManyEmployees')}
                  defaultValue={String(personalData?.QT_EMPREGADO_FIXO) ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="insuredNationalInstituteSocialSecurity"
                  label={t('registrationForm.insuredNationalInstituteSocialSecurity')}
                  defaultValue={personalData?.IE_SEGURADO_ESPECIAL ?? ''}
                />
              </Grid>
            </Grid>

            <StyledRegistrationFormSubtitle>{t('registrationForm.spouseData')}</StyledRegistrationFormSubtitle>
            <Grid container columnSpacing={1.5}>
              <Grid item xs={12} lg={8}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="spouseData"
                  label={t('registrationForm.spouse')}
                  defaultValue={personalData?.CONJUGE ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="weddingDate"
                  label={t('registrationForm.weddingDate')}
                  defaultValue={personalData?.DT_CASAMENTO ?? ''}
                />
              </Grid>
              <Grid item xs={12} sm={7} lg={2}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="spouseIndividualRegistration"
                  label={t('registrationForm.individualRegistration')}
                  defaultValue={StringUtils.maskCpf(String(personalData?.CPF_CONJUGE))}
                />
              </Grid>
              <Grid item xs={12} sm={5} lg={2}>
                <TextInputCadastralSheet
                  inputName="spouseGeneralRegistration"
                  label={t('registrationForm.generalRegistration')}
                  defaultValue={personalData?.RG_CONJUGE ?? ''}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={3}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="spouseIssuingBody"
                  label={t('registrationForm.issuingBody')}
                  defaultValue={personalData?.ORGAO_EXPEDIDOR_CONJUGE ?? ''}
                />
              </Grid>
              <Grid item xs={12} sm={8} lg={5}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="spouseBirthPlace"
                  label={t('registrationForm.birthPlace')}
                  defaultValue={personalData?.NATURAL_CONJUGE ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="spouseBirthDate"
                  label={t('registrationForm.birthDate')}
                  defaultValue={personalData?.DT_NASCIMENTO_CONJUGE ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="spouseProfession"
                  label={t('registrationForm.profession')}
                  defaultValue={personalData?.PROFISSAO_CONJUGE ?? ''}
                />
              </Grid>
            </Grid>

            <StyledRegistrationFormSubtitle>{t('registrationForm.bankData')}</StyledRegistrationFormSubtitle>
            <TextInputCadastralSheet
              isReadOnly
              inputName="name"
              label={t('registrationForm.name')}
              defaultValue={personalData?.NOME ?? ''}
            />
            <Grid container columnSpacing={1.5}>
              <Grid item xs={12} sm={6} lg={4}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="bankName"
                  label={t('registrationForm.bank')}
                  defaultValue={bankData?.NM_BANCO ?? ''}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="agency"
                  label={t('registrationForm.agency')}
                  defaultValue={bankData?.CD_AGENCIA ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextInputCadastralSheet
                  isReadOnly
                  inputName="accountNumber"
                  label={t('registrationForm.accountNumber')}
                  defaultValue={bankData?.NR_CONTA_BANCARIA ?? ''}
                />
              </Grid>
            </Grid>

            <StyledRegistrationFormSubtitle>{t('registrationForm.contact')}</StyledRegistrationFormSubtitle>
            <Grid container columnSpacing={1.5}>
              <Grid item xs={12} sm={6} lg={6}>
                <TextInputCadastralSheet
                  isReadOnly={!updateContact}
                  withBorder={updateContact}
                  inputName="telephone"
                  label={t('registrationForm.phone')}
                  defaultValue={contactData[0]?.NR_TELEFONE_FIXO ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextInputCadastralSheet
                  isReadOnly={!updateContact}
                  withBorder={updateContact}
                  inputName="cellPhone"
                  label={t('registrationForm.cellPhone')}
                  defaultValue={contactData[0]?.NR_CELULAR_WHATS ?? ''}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={1.5}>
              <Grid item xs={12} sm={6} lg={6}>
                <TextInputCadastralSheet
                  inputName="emailNF"
                  label={t('registrationForm.emailNF')}
                  defaultValue={contactData[0]?.DS_EMAIL_NF ?? ''}
                  isReadOnly={!updateContact}
                  withBorder={updateContact}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <TextInputCadastralSheet
                  isReadOnly={!updateContact}
                  withBorder={updateContact}
                  inputName="personalEmail"
                  label={t('registrationForm.personalEmail')}
                  defaultValue={contactData[0]?.DS_EMAIL_PESSOAL ?? ''}
                />
              </Grid>
            </Grid>

            <StyledButton
              color="frisiaPrimary"
              type={updateContact ? 'button' : 'submit'}
              onClick={() => setUpdateContact(!updateContact)}
              style={{ minWidth: '315px', margin: '35px' }}
            >
              <span>{!updateContact ? t('registrationForm.updateContact') : t('registrationForm.saveContact')}</span>
            </StyledButton>

            <StyledRegistrationFormSubtitle>{t('registrationForm.relationship')}</StyledRegistrationFormSubtitle>
            <GenericTableBlueHeader
              headerItems={tableHeaderRelationship}
              tableItems={
                relationshipData?.items?.map(item => ({
                  RelationshipCode: convertRelationshipCode(item?.RelationshipCode) ?? '',
                  SubjectPartyName: item?.SubjectPartyName ?? '',
                })) ?? [{}]
              }
              dataColor="#787878"
              isInvertLineColor
              isDontHaveMaximumWidth
            />

            {children.length > 0 && (
              <>
                <StyledRegistrationFormSubtitle>{t('registrationForm.children')}</StyledRegistrationFormSubtitle>
                <GenericTableBlueHeader
                  headerItems={tableHeaderChildren}
                  tableItems={children.map(item => {
                    return {
                      nome: item.NM_DEPENDENTE,
                      nascimento: dayjs(item.DT_NASCIMENTO).format('DD/MM/YY'),
                      sexo: item.IE_SEXO,
                      ddd: item.NR_DDD,
                      telefone: item.NR_FONE,
                      email: item.DS_EMAIL,
                    };
                  })}
                  dataColor="#787878"
                  isInvertLineColor
                  isDontHaveMaximumWidth
                />
              </>
            )}

            <StyledRegistrationFormSubtitle>{t('registrationForm.activities')}</StyledRegistrationFormSubtitle>
            <GenericTableBlueHeader
              headerItems={tableHeaderActivities}
              tableItems={cooperatorAreasActivities.map(item => {
                return {
                  atividade: item.DS_TIPO_ATIVIDADE,
                  status: item.STATUS,
                  classificacao: item.DS_ATIVIDADE,
                  adminssao: dayjs(item.ADMISSAO).format('DD/MM/YY'),
                };
              })}
              dataColor="#787878"
              isInvertLineColor
              isDontHaveMaximumWidth
            />

            <StyledRegistrationFormSubtitle>{t('registrationForm.properties')}</StyledRegistrationFormSubtitle>
            <GenericTableBlueHeader
              headerItems={tableHeaderProperties}
              tableItems={cooperatorProperties.map(property => ({
                property: property.NM_PROPRIEDADE,
                county: property.NM_MUNICIPIO,
              }))}
              dataColor="#787878"
              isInvertLineColor
              isDontHaveMaximumWidth
            />

            <StyledRegistrationFormSubtitle>{t('registrationForm.machinesAndEquipment')}</StyledRegistrationFormSubtitle>
            <GenericTableBlueHeader
              headerItems={tableHeaderMachinesAndEquipment}
              tableItems={cooperatorMachines.map(machine => ({
                nome: machine?.DS_TIPO_MAQUINA_EQPTO,
                chassi: machine?.NR_CHASSI,
                concervação: machine?.IE_CONSERVACAO,
                valor: machine?.VL_MOEDA_MAQEQUI?.toLocaleString('pt-BR'),
                alienado: machine?.IE_ALIENADO,
                vencimento: machine?.DT_VENCIMENTO_ALIENACAO,
              }))}
              dataColor="#787878"
              isInvertLineColor
              isDontHaveMaximumWidth
            />
          </form>
        </FormProvider>
      )}
    </StyledRegistrationFormBackground>
  );
};

const StyledHeaderForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  text-align: left;
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 16px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  span {
    margin-bottom: -1px;
  }
`;

const StyledFooterTableData = styled.td`
  color: ${({ theme }) => theme.color.frisiaPrimary};
  text-align: left;
`;

export default withTranslation()(PersonalData);
