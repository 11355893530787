import { AxiosResponse } from 'axios';
import { Authentication } from 'model/authentication';
import { CoreBodyRequest, CoreUserInfoReponse } from 'model/coreResponse';
import { ForgotPasswordParams } from 'model/forgot-password';
import { api, apiCore, apiDevCore, apiIndicators } from './api';

export const authenticationApi = () => {
  const login = (authentication: Authentication): Promise<AxiosResponse<Authentication>> => {
    return api.post<Authentication>('/JWT/api/Authenticate/login', authentication);
  };

  const coreUserInfo = (data: CoreBodyRequest): Promise<AxiosResponse<CoreUserInfoReponse>> => {
    return api.post<CoreUserInfoReponse>('/Core/api/Autenticar/Login', data);
  };

  const forgotPassword = (data: ForgotPasswordParams): Promise<AxiosResponse<ForgotPasswordParams>> => {
    return api.post<ForgotPasswordParams>(`/JWT/api/accounts/profile/forgotPassword`, data);
  };

  const getTokenIndicators = (data: string): Promise<AxiosResponse<CoreUserInfoReponse>> => {
    return apiIndicators.post<CoreUserInfoReponse>('/Token', data);
  };

  const coreDevUserInfo = (data: CoreBodyRequest): Promise<AxiosResponse<CoreUserInfoReponse>> => {
    return apiDevCore.post<CoreUserInfoReponse>('/Login', data);
  };

  const checkPageAcess = (register: string, path: string): Promise<AxiosResponse<null>> => {
    return apiCore.get<null>(`/Extranet/ValidaAcessoPaginas?CD_USUARIO_LOGADO=${register}&URL=cooperado.frisia.coop.br${path}`);
  };

  return {
    login,
    coreUserInfo,
    forgotPassword,
    getTokenIndicators,
    coreDevUserInfo,
    checkPageAcess,
  };
};

export default authenticationApi();
