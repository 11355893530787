import { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withTranslation, WithTranslation } from 'react-i18next';
import { api, apiCore, apiOracle } from '../../api/api';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { AxiosResponse } from 'axios';
import numeral from 'numeral';
import { StyledRegistrationFormBackground } from 'styled-components/StyledRegistrationForm';
import { ReactComponent as MachineIconSvg } from '../../images/frisia/icons/blue-infrastructure-icon.svg';
import { ReactComponent as EditCircleIconSvg } from '../../images/frisia/icons/blue-edit-circle-icon.svg';
import { ReactComponent as TrashCircleIconSvg } from '../../images/frisia/icons/red-trash-circle-icon.svg';
import { ReactComponent as SaveIconSvg } from '../../images/frisia/icons/blue-floppy-icon.svg';
import { ReactComponent as DiscardIconSvg } from '../../images/frisia/icons/blue-discard-changes-icon.svg';
import { ReactComponent as AddIconSvg } from '../../images/frisia/icons/blue-circle-add-icon.svg';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import GenericDropdown from 'components/GenericDropdown2';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Definição da interface para os equipamentos

interface IForm {
  property: string;
}

interface IBenfeitoria {
  nr_benfeitoria: number;
  nr_propriedade: number;
  cd_tipo_benfeitoria: number;
  vl_moeda: number;
  ds_observacao: string;
  qt_area_construida: number;
  ie_segurado: 'N' | 'S';
  ie_gravame: 'N' | 'S';
}

interface ITiposBenfeitoria {
  cd_tipo_benfeitoria: number;
  ds_tipo_benfeitoria: string;
}

interface IPropriedades {
  nr_propriedade: number;
  nm_propriedade: string;
}

const BenfeitoryList = ({ t }: WithTranslation) => {
  // Estados para os equipamentos, altura do container, índice de edição e equipamento original
  const [propertyOptions, setPropertyOptions] = useState<IPropriedades[]>([]);
  const [chosenProperty, setChosenProperty] = useState<IPropriedades | undefined>(undefined);
  const [benfeitories, setBenfeitories] = useState<IBenfeitoria[]>([]);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [originalBenfeitory, setOriginalBenfeitory] = useState<IBenfeitoria | null>(null);
  const [tiposDisponiveis, setTiposDisponiveis] = useState<ITiposBenfeitoria[]>([]);
  const [addingNew, setAddingNew] = useState<boolean>(false); // Estado para controlar adição de novo equipamento
  const userRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa ?? 0;
  });
  const formatCurrency = value => {
    if (value === null || value === undefined) {
      return 'R$ 0,00';
    }

    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      useGrouping: true,
    }).format(value);

    return formattedValue;
  };

  const schema = yup.object({
    harvest: yup.string().test('test-harvest', t('global.errorMessage.required'), () => chosenProperty != null),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fetchPropriedades = async () => {
      try {
        const response = await apiCore.get<IPropriedades[]>(`/Cadastro/GetPropriedadesAtivasCooperado?cd_pessoa=${userRegistration}`);
        setPropertyOptions(response.data);
      } catch (error) {
        console.error('Erro ao buscar propriedades:', error);
      }
    };

    fetchPropriedades();

    const fetchTipos = async () => {
      try {
        const response = await apiCore.get<ITiposBenfeitoria[]>(`/Cadastro/GetTiposBenfeitoria`);
        setTiposDisponiveis(response.data);
      } catch (error) {
        console.error('Erro ao buscar marcas:', error);
      }
    };

    fetchTipos();

    // Ajuste da altura do container com base na janela
    const updateContainerHeight = () => {
      const windowHeight = window.innerHeight;
      const maxHeight = windowHeight * 0.55;
      setContainerHeight(maxHeight);
    };

    updateContainerHeight();
    window.addEventListener('resize', updateContainerHeight);

    return () => {
      window.removeEventListener('resize', updateContainerHeight);
    };
  }, [userRegistration]);

  useEffect(() => {
    console.log(chosenProperty);
    if (chosenProperty) {
      handlePropriedadeChange(chosenProperty.nr_propriedade);
    }
  }, [chosenProperty]); // Adiciona chosenProperty como dependência

  const handlePropriedadeChange = async (nr_propriedade: number) => {
    try {
      const response = await apiCore.get<IBenfeitoria[]>(`/Cadastro/GetDetalhesBenfeitorias?nr_propriedade=${nr_propriedade}`);
      setBenfeitories(response.data); // Atualiza o estado com os dados recebidos
    } catch (error) {
      console.error('Erro ao buscar benfeitorias:', error);
    }
  };

  // Função para lidar com o clique em editar
  const handleEditClick = (index: number) => {
    setEditingIndex(index);
    setOriginalBenfeitory({ ...benfeitories[index] });
  };

  // Função para lidar com o clique em deletar
  const handleDeleteClick = (nrBenfeitory: number) => {
    const confirmDelete = window.confirm('Deseja realmente excluir esta linha? Esta operação não poderá ser desfeita.');
    if (confirmDelete) {
      deleteBenfeitory(nrBenfeitory);
    }
  };

  // Função para deletar um equipamento
  const deleteBenfeitory = async (nrBenfeitory: number) => {
    try {
      const response = await apiCore.delete('/Cadastro/DeleteBenfeitoriaCooperado', {
        params: {
          nr_propriedade: chosenProperty?.nr_propriedade ?? 0,
          nr_benfeitoria: nrBenfeitory,
        },
      });

      if (response.data.sucesso) {
        toast.success(response.data.mensagem, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000, // Fechar automaticamente após 5 segundos
        });

        const updatedBenfeitories = benfeitories.filter(benfeitory => benfeitory.nr_benfeitoria !== nrBenfeitory);
        setBenfeitories(updatedBenfeitories);
      } else {
        toast.error(response.data.mensagem, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error('Erro ao excluir benfeitoria.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      console.error('Erro ao excluir benfeitoria:', error);
    }
  };

  // Função para lidar com o clique em salvar
  const handleSaveClick = (editedBenfeitory: IBenfeitoria) => {
    const updatedBenfeitories = [...benfeitories];
    updatedBenfeitories[editingIndex as number] = { ...editedBenfeitory };
    setBenfeitories(updatedBenfeitories);
    setEditingIndex(null);
    setOriginalBenfeitory(null);
    saveBenfeitory(editedBenfeitory);
  };

  // Função para salvar um equipamento (merge)
  const saveBenfeitory = async (benfeitory: IBenfeitoria) => {
    try {
      const response = await apiCore.post('/Cadastro/MergeBenfeitoriaCooperado', benfeitory);

      if (response.data.sucesso) {
        toast.success(response.data.mensagem, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });

        // Atualiza a lista de equipamentos com os dados salvos
        const updatedBenfeitories = [...benfeitories];
        updatedBenfeitories[editingIndex as number] = { ...benfeitory };
        setBenfeitories(updatedBenfeitories);
        setEditingIndex(null);
        setOriginalBenfeitory(null);
      } else {
        toast.error(response.data.mensagem, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error('Erro ao salvar benfeitoria.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      console.error('Erro ao salvar benfeitoria:', error);
    }
  };

  // Função para lidar com o clique em descartar alterações
  const handleDiscardClick = () => {
    if (originalBenfeitory && editingIndex !== null) {
      const updatedBenfeitories = [...benfeitories];
      updatedBenfeitories[editingIndex] = { ...originalBenfeitory };
      setBenfeitories(updatedBenfeitories);
    } else if (addingNew) {
      // Se estiver adicionando novo, remove o último equipamento adicionado
      setBenfeitories(prevBenfeitories => prevBenfeitories.slice(0, -1));
    }
    setEditingIndex(null);
    setOriginalBenfeitory(null);
  };

  // No retorno do componente, ajuste a renderização da linha para verificar se o equipamento está na lista atual
  {
    benfeitories.map((benfeitory, index) => (
      <StyledTableRow key={index} style={{ display: benfeitories.includes(benfeitory) ? 'table-row' : 'none' }}>
        {/* Renderização do conteúdo da linha aqui */}
      </StyledTableRow>
    ));
  }

  // Função para lidar com a mudança de input (texto)
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, field: keyof IBenfeitoria) => {
    if (editingIndex !== null) {
      const updatedBenfeitories = [...benfeitories];
      let value: any = e.target.value;

      if (field === 'vl_moeda') {
        value = value.replace(',', '.'); // Substitui vírgula por ponto
      }

      updatedBenfeitories[editingIndex] = {
        ...updatedBenfeitories[editingIndex],
        [field]: value,
      };
      setBenfeitories(updatedBenfeitories);
    }
  };

  // Função para lidar com a mudança no dropdown (marca)
  const handleTipoChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (editingIndex !== null) {
      const updatedBenfeitories = [...benfeitories];
      updatedBenfeitories[editingIndex].cd_tipo_benfeitoria = parseInt(e.target.value); // Converte para número
      setBenfeitories(updatedBenfeitories);
    }
  };

  // Função para lidar com a mudança no dropdown (booleano)
  const handleDropdownChange = (value: boolean, field: keyof IBenfeitoria) => {
    if (editingIndex !== null) {
      const updatedBenfeitories = [...benfeitories];
      // Utilize o type assertion para informar ao TypeScript que field é uma chave válida de IEquipment
      updatedBenfeitories[editingIndex] = {
        ...updatedBenfeitories[editingIndex],
        [field]: value ? 'S' : 'N',
      };
      setBenfeitories(updatedBenfeitories);
    }
  };

  // Função para lidar com o clique em adicionar novo equipamento
  const handleAddClick = () => {
    setAddingNew(true);

    // Encontrar o maior nr_equipamento existente na lista
    const maxNrBenfeitoria = benfeitories.length > 0 ? Math.max(...benfeitories.map(benfeitory => benfeitory.nr_benfeitoria)) : 0; // Caso não haja nenhum equipamento, o valor inicial será 0

    // O próximo nr_equipamento será o maior número + 1
    const newNrBenfeitoria = maxNrBenfeitoria + 1;
    // Lógica para adicionar um novo equipamento ao estado de equipamentos
    const newBenfeitory: IBenfeitoria = {
      nr_benfeitoria: newNrBenfeitoria,
      nr_propriedade: chosenProperty?.nr_propriedade ?? 0,
      cd_tipo_benfeitoria: 300,
      vl_moeda: 0,
      ds_observacao: '',
      qt_area_construida: 0,
      ie_segurado: 'N',
      ie_gravame: 'N',
    };
    setBenfeitories([...benfeitories, newBenfeitory]);
    setEditingIndex(benfeitories.length); // Foca na última linha para edição
    setOriginalBenfeitory(null);
  };

  return (
    <>
      <StyledFilterContainer filterWidth={500}>
        <GenericDropdown<IPropriedades>
          label={t('registrationForm.selectProperty')}
          placeholder={t('registrationForm.selectProperty')}
          listShowDropdown={propertyOptions} // Passa a lista de objetos
          displayKey="nm_propriedade" // Usa `nm_propriedade` para exibição
          getSelectedValue={selectedItem => {
            setChosenProperty(selectedItem); // Recebe o objeto selecionado
          }}
          errorText={methods.formState.errors.property?.message}
        />
      </StyledFilterContainer>
      <StyledRegistrationFormBackground>
        <StyledHeaderForm>
          <StyledTitleContainer>
            <MachineIconSvg />
            <span>{t('registrationForm.benfeitoryData')}</span>
          </StyledTitleContainer>
          {/* Ícone de adicionar somente é exibido quando o editingIndex for null */}
          {editingIndex === null && chosenProperty?.nr_propriedade != undefined && chosenProperty?.nr_propriedade != null && (
            <IconWrapper title="Adicionar nova benfeitoria" onClick={handleAddClick}>
              <AddIconSvg />
              <span>{t('registrationForm.addBenfeitory')}</span>
            </IconWrapper>
          )}
        </StyledHeaderForm>
        <StyledContainer style={{ maxHeight: `${containerHeight}px` }}>
          <StyledTable>
            <thead>
              <StyledTableHeaderRow>
                <StyledTableHeaderCell style={{ width: '120px' }}>AÇÕES</StyledTableHeaderCell>
                <StyledTableHeaderCell style={{ width: '110px' }}>CÓDIGO</StyledTableHeaderCell>
                <StyledTableHeaderCell style={{ width: '500px' }}>TIPO BENFEITORIA</StyledTableHeaderCell>
                <StyledTableHeaderCell style={{ width: '160px', zIndex: '1' }}>VALOR</StyledTableHeaderCell>
                <StyledTableHeaderCell style={{ width: '500px', zIndex: '1' }}>OBSERVAÇÕES</StyledTableHeaderCell>
                <StyledTableHeaderCell style={{ width: '150px', zIndex: '1' }}>ÁREA CONSTR.</StyledTableHeaderCell>
                <StyledTableHeaderCell style={{ width: '130px', zIndex: '1' }}>SEGURADO</StyledTableHeaderCell>
                <StyledTableHeaderCell style={{ width: '130px', zIndex: '1' }}>GRAVAME</StyledTableHeaderCell>
              </StyledTableHeaderRow>
            </thead>
            <tbody>
              {benfeitories.map((benfeitoria, index) => (
                <StyledTableRow key={index} style={{ display: editingIndex !== null && editingIndex !== index ? 'none' : 'table-row' }}>
                  <StyledTableIconCell>
                    {editingIndex === null && (
                      <IconWrapper title="Editar linha" onClick={() => handleEditClick(index)}>
                        <EditCircleIconSvg />
                      </IconWrapper>
                    )}

                    {/* Ícone de salvar */}
                    {editingIndex === index && (
                      <IconWrapper title="Salvar alterações" onClick={() => handleSaveClick(benfeitoria)}>
                        <SaveIconSvg />
                      </IconWrapper>
                    )}

                    {/* Ícone de descartar */}
                    {editingIndex === index && (
                      <IconWrapper title="Descartar alterações" onClick={handleDiscardClick}>
                        <DiscardIconSvg />
                      </IconWrapper>
                    )}

                    {/* Ícone de excluir */}
                    {editingIndex === null && (
                      <IconWrapper title="Excluir linha" onClick={() => handleDeleteClick(benfeitoria.nr_benfeitoria)}>
                        <TrashCircleIconSvg />
                      </IconWrapper>
                    )}
                  </StyledTableIconCell>
                  <StyledTableCell>{benfeitoria.nr_benfeitoria}</StyledTableCell>
                  <StyledTableCell>
                    {editingIndex === index ? (
                      <select value={benfeitoria.cd_tipo_benfeitoria.toString()} onChange={handleTipoChange}>
                        {tiposDisponiveis.map(tipo => (
                          <option key={tipo.cd_tipo_benfeitoria} value={tipo.cd_tipo_benfeitoria.toString()}>
                            {tipo.ds_tipo_benfeitoria}
                          </option>
                        ))}
                      </select>
                    ) : (
                      tiposDisponiveis.find(tipo => tipo.cd_tipo_benfeitoria === benfeitoria.cd_tipo_benfeitoria)?.ds_tipo_benfeitoria
                    )}
                  </StyledTableCell>
                  <StyledEditableTableCell editing={editingIndex === index}>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        value={benfeitoria.vl_moeda}
                        onChange={e => handleInputChange(e, 'vl_moeda')}
                        placeholder="Digite aqui"
                      />
                    ) : (
                      formatCurrency(benfeitoria.vl_moeda)
                    )}
                  </StyledEditableTableCell>
                  <StyledEditableTableCell editing={editingIndex === index}>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        value={benfeitoria.ds_observacao}
                        onChange={e => handleInputChange(e, 'ds_observacao')}
                        placeholder="Digite aqui"
                      />
                    ) : (
                      benfeitoria.ds_observacao
                    )}
                  </StyledEditableTableCell>
                  <StyledEditableTableCell editing={editingIndex === index}>
                    {editingIndex === index ? (
                      <input
                        type="number"
                        value={benfeitoria.qt_area_construida}
                        onChange={e => handleInputChange(e, 'qt_area_construida')}
                        placeholder="Digite aqui"
                      />
                    ) : (
                      benfeitoria.qt_area_construida
                    )}
                  </StyledEditableTableCell>
                  <StyledEditableTableCell editing={editingIndex === index}>
                    {editingIndex === index ? (
                      <select value={benfeitoria.ie_segurado} onChange={e => handleDropdownChange(e.target.value === 'S', 'ie_segurado')}>
                        <option value="S">SIM</option>
                        <option value="N">NÃO</option>
                      </select>
                    ) : benfeitoria.ie_segurado === 'S' ? (
                      'SIM'
                    ) : (
                      'NÃO'
                    )}
                  </StyledEditableTableCell>
                  <StyledEditableTableCell editing={editingIndex === index}>
                    {editingIndex === index ? (
                      <select value={benfeitoria.ie_gravame} onChange={e => handleDropdownChange(e.target.value === 'S', 'ie_gravame')}>
                        <option value="S">SIM</option>
                        <option value="N">NÃO</option>
                      </select>
                    ) : benfeitoria.ie_gravame === 'S' ? (
                      'SIM'
                    ) : (
                      'NÃO'
                    )}
                  </StyledEditableTableCell>
                </StyledTableRow>
              ))}
            </tbody>
          </StyledTable>
        </StyledContainer>
      </StyledRegistrationFormBackground>{' '}
    </>
  );
};

const StyledHeaderForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.font.montserratMedium};
  color: ${({ theme }) => theme.color.frisiaPrimary};
  font-size: 16px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  span {
    margin-bottom: -1px;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  border: 1px solid #ddd;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const StyledTableHeaderRow = styled.tr`
  background: #f1f1f1;
`;

const StyledTableHeaderCell = styled.th`
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  position: sticky;
  top: 0;
  background-color: #f1f1f1; /* Cor de fundo para o cabeçalho fixo */
`;

const StyledTableRow = styled.tr`
  &:nth-child(even) {
    background: #f9f9f9;
  }
`;

const StyledTableCell = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
`;

const StyledTableIconCell = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;

const StyledEditableTableCell = styled(StyledTableCell)<{ editing: boolean }>`
  position: relative;
  padding: 8px;
  border: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;

  input,
  select {
    width: 100%;
    border: none;
    background: ${({ editing }) => (editing ? '#f0f8ff' : 'transparent')}; /* Cor de fundo para indicar edição */
    font-family: inherit;
    font-size: inherit;
    padding: 4px 8px;
    box-sizing: border-box;
  }
`;

const IconWrapper = styled.div`
  display: inline-block; /* Torna os ícones em linha */
  cursor: pointer;
  margin-right: 5px;

  svg {
    width: 40px; /* Ajusta o tamanho dos ícones */
    height: 40px;
    fill: #007bff;
  }

  &:hover {
    svg {
      fill: #0056b3;
    }
  }
`;

export default withTranslation()(BenfeitoryList);
